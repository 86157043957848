import React from 'react';
import { renderFilterCell, convertToLocalDate } from 'smart-react';
import {
  Tenants,
  testTypes,
  groupTypes,
  BooleanValues,
} from '../../Components/Lookup/LookupUtility';

/**
 * Returns a filter cell for the Status column.
 * @param {Object} props - Component props.
 * @returns {JSX.Element} JSX element representing the filter cell.
 */
export const TenantFilterCell = (props) => {
  var data = Tenants();
  return renderFilterCell(props, data, 'dropdown');
};

/**
 * Returns a filter cell for the test type column.
 * @param {Object} props - Component props.
 * @returns {JSX.Element} JSX element representing the filter cell.
 */
export const TestTypesFilterCell = (props) => {
  var data = testTypes();
  return renderFilterCell(props, data, 'dropdown');
};

/**
 * Returns a filter cell for the test group column.
 * @param {Object} props - Component props.
 * @returns {JSX.Element} JSX element representing the filter cell.
 */
export const TestGroupFilterCell = (props) => {
  var data = groupTypes();
  return renderFilterCell(props, data, 'dropdown');
};
/**
 * Handles Boolean Values filter cell.
 * @param {*} props
 * @returns JSX.Element
 */
export const BooleanValuesFilterCell = (props) => {
  var booleanValues = BooleanValues();
  return renderFilterCell(props, booleanValues, 'dropdown');
};
/**
 * Returns a grid date.
 * @param {*} props
 * @returns {JSX.Element} returns row cell
 */
export const GridDateCell = (props) => {
  let value = '';
  const { dataItem, field } = props;

  if (field?.includes('.')) {
    const fields = field?.split('.');
    value = dataItem[fields[0]][fields[1]];
  } else {
    value = dataItem[field];
  }
  return <td>{convertToLocalDate(value)}</td>;
};
