import React from 'react';
/**
 * data Columns for grid and mobile view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Data Columns Array
 */
export const DataColumns = ({
  editScreen,
  TenantFilterCell,
  dateRangeFilter,
  GridDateCell,
  setDateRangeFilter,
  MultiViewCalenderFilterCell,
}) => {
  const dataColumns = [
    {
      field: 'Row No',
      title: 'row_no',
      editable: false,
      show: false,
      width: '250px',
      width: '250px',
      minResizableWidth: 150,
      filterable: true,
      excludeFromFilter: true,
    },
    {
      field: 'les_cmd_id',
      title: 'Command Id',
      editable: false,
      show: true,
      width: '600px',
      className: 'edit-link',
      minResizableWidth: 50,
      cell: (props) => (
        <td
          className='edit-link'
          onClick={() => {
            editScreen(props.dataItem);
          }}
        >
          {props.dataItem.les_cmd_id}
        </td>
      ),
    },
    {
      field: 'TenantName',
      title: 'Tenant',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 150,
      filterable: true,
      filterCell: (props) =>
        TenantFilterCell({
          ...props,
          filterType: 'dropdown',
        }),
    },
    {
      field: 'syntax',
      title: 'Syntax',
      editable: false,
      show: true,
      width: '500px',
      minResizableWidth: 50,
      cell: (props) => <td>{props.dataItem.syntax}</td>,
    },
    {
      field: 'grp_nam',
      title: 'Group Name',
      editable: false,
      show: true,
      width: '150px',
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: 'mod_usr_id',
      title: 'Modified By',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'moddte',
      title: 'Modified Date',
      editable: false,
      show: true,
      width: '300px',
      minResizableWidth: 50,
      filter: 'date',
      type: 'dateRange',
      filterCell: (props) =>
        MultiViewCalenderFilterCell({
          ...props,
          dateRangefilter: dateRangeFilter,
          setDateRangeFilter,
          field: 'moddte',
        }),
      isRequired: true,
      cell: GridDateCell,
    },
  ];

  return dataColumns;
};
export default DataColumns;
