import { getAllTenant } from "../../../../Utils/Auth/ValidatePermissions";
import { LOOKUP_TYPES } from "../../../../constants/applicationConstants";
/**
 * Retrieves a list of Tenants.
 * @returns {Promise<object[]>} A list of shifts, where each Tenants is represented as an object.
 */
export const Tenants = () => {
  const data = getAllTenant();
  return data
    ? [
        { label: "(All)", value: "" },
        ...data.map((item) => ({
          label: item.TenantName,
          value: item.TenantName,
        })),
      ]
    : [];
};
/**
 * Retrieves a list of Test Type.
 */
export const testTypes = () => {
  const data = JSON.parse(localStorage.getItem(LOOKUP_TYPES?.testTypes));
  return data
    ? [
        { label: "(All)", value: "" },
        ...data.map((item) => ({
          label: item.DisplayName,
          value: item.DisplayName,
        })),
      ]
    : [];
};
/**
 * Retrieves a list of gorup.
 */
export const groupTypes = () => {
  const data = JSON.parse(localStorage.getItem(LOOKUP_TYPES?.groupTypes));
  return data
    ? [
        { label: "(All)", value: "" },
        ...data.map((item) => ({
          label: item.DisplayName,
          value: item.DisplayName,
        })),
      ]
    : [];
};
/**
 * Retrieves a Boolean Values.
 */
export const BooleanValues = () => {
  const booleanValues = [
    { label: "(All)", value: "" },
    { label: "Yes", value: "1" },
    { label: "No", value: "0" },
  ];
  return booleanValues.map((item, i) => ({
    label: item.label,
    value: item.value,
  }));
};
